@charset "utf-8";

@import '../mixin';
@import '../variable';

.mediaSection {
    background-color: #ECECEC;
    padding: 85px 3% 120px;
    @media screen and (max-width: 1300px) {
        padding: 70px 3% 80px;
    }
    @media screen and (max-width: $lg) {
        padding: 60px 3%;
    }
    @media screen and (max-width: $sm) {
        padding: 45px 5% 30px;
    }
    .inner {
        max-width: 1260px;
        width: 100%;
        margin: auto;
        .heading {
            text-align: center;
            line-height: 1;
            margin-bottom: 55px;
            @media screen and (max-width: $sm) {
                margin-bottom: 25px;
            }
            h2 {
                font-size: 56px;
                font-family: $font-secondary;
                letter-spacing: 5px;
                margin-bottom: 10px;
                font-weight: normal;
                @media screen and (max-width: $lg) {
                    font-size: 45px;
                }
                @media screen and (max-width: $sm) {
                    font-size: 34px;
                    margin-bottom: 5px;
                }
            }
            p {
                font-size: 17px;
                color: #808080;
                @media screen and (max-width: $lg) {
                    font-size: 14px;
                }
                @media screen and (max-width: $sm) {
                    font-size: 12px;
                }
            }
        }
        ul {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: $sm) {
                flex-wrap: wrap;
            }
            li {
                background-color: #FFF;
                width: 290px;
                margin-bottom: 40px;
                @media screen and (max-width: 1300px) {
                    width: 22.75%;
                }
                @media screen and (max-width: $sm) {
                    width: 47.5%;
                    margin-bottom: 7.5%;
                }
                a {
                    display: block;
                    transition: $animate;
                    .img {
                        height: 260px;
                        overflow: hidden;
                        @media screen and (max-width: 1300px) {
                            height: 16vw;
                        }
                        @media screen and (max-width: $sm) {
                            height: 35vw;
                        }
                        div {
                            width: 100%;
                            height: 100%;
                            transition: $animate;
                            transform: scale(1);
                            background-size: contain !important;
                        }
                    }
                    .blog-text {
                        padding: 25px 30px;
                        @media screen and (max-width: 1300px) {
                            padding: 15px;
                        }
                        @media screen and (max-width: $sm) {
                            border-top: 1px solid #C5C5C5;
                            padding: 10px 12px;
                        }
                        span {
                            font-size: 14px;
                            color: #808080;
                            display: block;
                            margin-bottom: 10px;
                            @media screen and (max-width: $sm) {
                                margin-bottom: 3px;
                                font-size: 10px;
                            }
                        }
                        h3 {
                            overflow: hidden;
                            max-height: 48px;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            @media screen and (max-width: $sm) {
                                font-size: 14px;
                                max-height: 42px;
                            }
                        }
                    }
                    &:hover {
                        opacity: 0.8;
                        .img {
                            div {
                                transform: scale(1.05);
                            }
                        }
                    }
                }
            }
        }
    }
}