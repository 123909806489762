@charset "utf-8";

@import '../mixin';
@import '../variable';

@import '../component/media.scss';

#bannerMominoki {
    margin-bottom: 75px;
    padding: 0 5%;
    @media screen and (max-width: 767px) {
        margin-bottom: 40px;
    }
    .inner {
        max-width: 1200px;
        width: 100%;
        margin: auto;
        img {
            width: 100%;
            &.sp {
                display: none;
            }
            @media screen and (max-width: 767px) {
                &.pc {
                    display: none;
                }
                &.sp {
                    display: block;
                }
            }
        }
    }
}

#firstSection {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
    @media screen and (max-width: $xl) {
        padding-bottom: 60px;
    }
    @media screen and (max-width: $md) {
        flex-direction: column;
    }
    @media screen and (max-width: $sm) {
        padding-bottom: 50px;
    }
    .outer {
        width: 39.35%;
        @media screen and (max-width: $md) {
            width: 100%;
        }
        .item {
            position: relative;
            padding-top: 48px;
            @media screen and (max-width: $xl) {
                padding-top: 40px;
            }
            @media screen and (max-width: $lg) {
                padding-top: 30px;
            }
            .headingSection {
                position: absolute;
                top: -50px;
                left: 0;
                width: 250px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                background-image: url(../../img/icon/trapezium.png);
                @media screen and (max-width: $xl) {
                    width: 220px;
                    height: 45px;
                    top: -45px;
                }
                @media screen and (max-width: $lg) {
                    width: 200px;
                    height: 40px;
                    top: -40px;
                }
                @media screen and (max-width: $md) {
                    top: -39px;
                }
                @media screen and (max-width: $sm) {
                    width: 155px;
                    height: 31px;
                    top: -30px;
                }
                h2 {
                    font-size: 22px;
                    font-family: $font-secondary;
                    font-weight: normal;
                    @media screen and (max-width: $xl) {
                        font-size: 20px;
                    }
                    @media screen and (max-width: $lg) {
                        font-size: 18px;
                    }
                    @media screen and (max-width: $sm) {
                        font-size: 16px;
                        letter-spacing: 0;
                    }
                }
            }
            ul {
                margin-bottom: 40px;
                @media screen and (max-width: $md) {
                    margin-bottom: 30px;
                }
                @media screen and (max-width: $sm) {
                    margin-bottom: 0;
                }
            }
            .btnBox {
                display: flex;
                justify-content: flex-end;
                padding-right: 8%;
                @media screen and (max-width: $md) {
                    justify-content: center;
                    padding-right: 0;
                }
                @media screen and (max-width: $sm) {
                    position: absolute;
                    right: 5%;
                    top: 15px;
                }
                a {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: $font-secondary;
                    position: relative;
                    transition: $animate;
                    img {
                        width: 30px;
                        margin-left: 8px;
                        position: relative;
                        bottom: 1px;
                        right: 0;
                        transition: $animate;
                    }
                    &:hover {
                        opacity: 0.8;
                        img {
                            right: -5px;
                        }
                    }
                }
            }
        }
        &.first {
            @media screen and (max-width: $md) {
                padding-bottom: 70px;
                background-color: #F1F1F1;
            }
            @media screen and (max-width: $sm) {
                padding-bottom: 75px;
            }
            .item {
                padding: 48px 90px 0 100px;
                @media screen and (max-width: $xl) {
                    padding: 40px 7.5% 0;
                }
                @media screen and (max-width: $lg) {
                    padding: 30px 7.5% 0;
                }
                @media screen and (max-width: $sm) {
                    padding: 50px 8% 0;
                }
                .headingSection {
                    @media screen and (max-width: $md) {
                        background-image: url(../../img/icon/trapezium-grey.png);
                    }
                }
                ul {
                    .blog-list {
                        border-bottom: 1px solid $color-gray;
                        a {
                            padding: 20px 0;
                            display: block;
                            position: relative;
                            @media screen and (max-width: $lg) {
                                padding: 15px 0;
                            }
                            .img {
                                display: none;
                            }
                            .blog-text {
                                .blog-text-top {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    font-size: 15px;
                                    @media screen and (max-width: $sm) {
                                        font-size: 14px;
                                        align-items: center;
                                    }
                                    span {
                                        width: 125px;
                                        @media screen and (max-width: $sm) {
                                            width: 78px;
                                            font-size: 12px;
                                            letter-spacing: 0;
                                        }
                                    }
                                    .blog-title {
                                        flex: 1;
                                        overflow: hidden;
                                        max-height: 21px;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        position: relative;
                                        left: 0;
                                        transition: $animate;
                                    }
                                }
                            }
                            &:hover {
                                .blog-text {
                                    .blog-text-top {
                                        .blog-title {
                                            left: 5px;
                                            opacity: 0.8;
                                        }
                                    }
                                }
                            }
                        }
                        &:first-child {
                            a {
                                padding-top: 0;
                            }
                        }
                        &:last-child {
                            border-bottom: 0;
                            a {
                                padding-bottom: 0;
                            }
                        }
                        &:nth-child(2) {
                            a {
                                padding-top: 0;
                            }
                        }
                    }
                }
                .btnBox {
                    padding-right: 0;
                }
            }
        }
        &.second {
            width: auto;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding-right: 50px;
            @media screen and (max-width: $xl) {
                padding-right: 0.5%;
            }
            @media screen and (max-width: $md) {
                flex-direction: column;
                padding-right: 0;
            }
            .item {
                width: 50%;
                @media screen and (max-width: $md) {
                    width: 100%;
                    padding: 50px 7.5% 90px;
                }
                @media screen and (max-width: $sm) {
                    padding: 50px 5% 75px;
                }
                &:last-child {
                    @media screen and (max-width: $md) {
                        background-color: #F1F1F1;
                        padding: 50px 7.5%;
                        .headingSection {
                            background-image: url(../../img/icon/trapezium-grey.png);
                        }
                    }
                    @media screen and (max-width: $sm) {
                        padding: 50px 5% 45px;
                    }
                }
                ul {
                    display: flex;
                    justify-content: flex-start;
                    @media screen and (max-width: $md) {
                        justify-content: space-between;
                    }
                    .blog-list {
                        width: 42%;
                        margin-right: 8%;
                        @media screen and (max-width: $md) {
                            margin-right: 0;
                            width: 46.25%;
                        }
                        @media screen and (max-width: $sm) {
                            width: 47.5%;
                        }
                        a {
                            display: block;
                            .img {
                                width: 100%;
                                height: 140px;
                                margin-bottom: 15px;
                                overflow: hidden;
                                @media screen and (max-width: $lg) {
                                    height: 10vw;
                                }
                                @media screen and (max-width: $md) {
                                    height: 25vw;
                                }
                                @media screen and (max-width: $sm) {
                                    height: 35vw;
                                    margin-bottom: 5px;
                                }
                                div {
                                    width: 100%;
                                    height: 100%;
                                    transition: $animate;
                                    transform: scale(1);
                                }
                            }
                            .blog-text {
                                .blog-text-top {
                                    font-size: 15px;
                                    @media screen and (max-width: $sm) {
                                        font-size: 14px;
                                    }
                                    span {
                                        display: block;
                                        @media screen and (max-width: $sm) {
                                            font-size: 12px;
                                        }
                                    }
                                    h3 {
                                        overflow: hidden;
                                        max-height: 22.5px;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                    }
                                }
                            }
                            &:hover {
                                .img {
                                    div {
                                        transform: scale(1.05);
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

#yotubes {
    margin: auto;
    width: 565px;
    margin-bottom: 25px;
    iframe {
        width: 100%;
        height: 38vh;
    }
    @media screen and (max-width: $md) {
        iframe {
            height: 39vh;
        }
    }
    @media screen and (max-width: $sm) {
        width: 100%;
        margin-bottom: 0;
        padding: 30px;
        iframe {
            width: 100%;
            height: 23vh;
        }
    }
}

#descPage {
    padding: 0 3% 70px;
    @media screen and (max-width: $lg) {
        padding: 0 3% 60px;
    }
    @media screen and (max-width: $md) {
        padding: 0 3% 40px;
    }
    @media screen and (max-width: $sm) {
        padding: 0 8% 30px;
    }
    h2 {
        text-align: center;
        font-size: 38px;
        font-family: $font-secondary;
        font-weight: normal;
        margin-bottom: 25px;
        @media screen and (max-width: $xl) {
            font-size: 38px;
        }
        @media screen and (max-width: $lg) {
            font-size: 36px;
        }
        @media screen and (max-width: $md) {
            font-size: 32px;
        }
        @media screen and (max-width: $sm) {
            font-size: 5.1vw;
            margin-bottom: 15px;
        }
    }
    .desc {
        p {
            text-align: center;
            line-height: 2;
            @media screen and (max-width: $lg) {
                font-size: 15px;
            }
            @media screen and (max-width: $md) {
                font-size: 14px;
                display: inline;
                text-align: left;
            }
        }
    }
}

#secondSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 120px;
    @media screen and (max-width: $xl) {
        margin-bottom: 90px;
    }
    @media screen and (max-width: $lg) {
        margin-bottom: 60px;
    }
    @media screen and (max-width: $md) {
        flex-direction: column;
    }
    .slide-img {
        width: 63.27vw;
        @media screen and (max-width: $md) {
            width: 100%;
        }
        .gallery-top {
            .swiper-wrapper {
                .swiper-slide {
                    width: 100%;
                    height: 29.34vw;
                    @media screen and (max-width: $lg) {
                        height: 31.84vw;
                    }
                    @media screen and (max-width: $md) {
                        height: 35vw;
                    }
                    @media screen and (max-width: $sm) {
                        height: 49.5vw;
                    }
                }
            }
        }
    }
    .structure {
        flex: 1;
        padding-left: 6%;
        @media screen and (max-width: $xl) {
            padding-left: 5%;
        }
        @media screen and (max-width: $lg) {
            padding-left: 3%;
        }
        @media screen and (max-width: $md) {
            padding: 30px 3% 0;
            flex: unset;
            width: 100%;
        }
        ul {
            margin-bottom: 50px;
            @media screen and (max-width: $lg) {
                margin-bottom: 30px;
            }
            @media screen and (max-width: $md) {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
            }
            @media screen and (max-width: $sm) {
                max-width: 300px;
                width: 100%;
                margin: auto;
                justify-content: flex-start;
            }
            &.listDesktop {
                @media screen and (max-width: $sm) {
                    display: none;
                }
            }
            &.listMobile {
                display: none;
                @media screen and (max-width: $sm) {
                    display: flex;
                    margin-bottom: 15px;
                }
            }
            li {
                font-size: 24px;
                font-family: $font-secondary;
                margin-bottom: 30px;
                font-weight: normal;
                @media screen and (max-width: $lg) {
                    font-size: 20px;
                    margin-bottom: 15px;
                }
                @media screen and (max-width: $md) {
                    width: 50%;
                }
                @media screen and (max-width: $sm) {
                    font-size: 16px;
                    font-feature-settings: "palt";
                    letter-spacing: 1px;
                    &:nth-child(2),
                    &:last-child {
                        padding-left: 15px;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        a {
            width: 100%;
            height: 64px;
            background-color: #ADADAD;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 24px;
            font-weight: normal;
            color: #FFF;
            padding: 0 15px 0 30px;
            @media screen and (max-width: $xl) {
                font-size: 20px;
            }
            @media screen and (max-width: $lg) {
                font-size: 18px;
            }
            @media screen and (max-width: $md) {
                max-width: 350px;
                justify-content: center;
                margin: auto;
            }
            @media screen and (max-width: $sm) {
                max-width: 300px;
                width: 100%;
                height: 45px;
                margin: auto;
                font-size: 16px;
            }
            svg {
                margin-left: 15px;
                width: 32px;
                height: 6px;
                defs {
                    stroke: #FFF !important;
                }
                @media screen and (max-width: $lg) {
                    width: 30px;
                    height: 5px;
                }
                @media screen and (max-width: $sm) {
                    width: 24px;
                    height: 4px;
                    margin-left: 10px;
                }
            }
        }
    }
}

#thirdSection {
    background-image: url(../../img/bg-land.jpg);
    padding: 80px 3% 35px;
    color: #FFF;
    @media screen and (max-width: $lg) {
        padding: 60px 3% 30px;
    }
    @media screen and (max-width: $sm) {
        padding: 50px 5%;
    }
    .heading {
        text-align: center;
        margin-bottom: 20px;
        @media screen and (max-width: $sm) {
            margin-bottom: 15px;
        }
        h2 {
            font-size: 38px;
            font-weight: normal;
            font-family: $font-secondary;
            @media screen and (max-width: $lg) {
                font-size: 38px;
            }
            @media screen and (max-width: $md) {
                font-size: 32px;
            }
            @media screen and (max-width: $sm) {
                font-size: 20px;
                line-height: 1.75;
            }
            &.for-desktop {
                @media screen and (max-width: $sm) {
                    display: none;
                }
            }
            &.for-mobile {
                display: none;
                @media screen and (max-width: $sm) {
                    display: block;
                }
            }
        }
    }
    .desc {
        text-align: center;
        margin-bottom: 45px;
        @media screen and (max-width: $lg) {
            margin-bottom: 30px;
        }
        @media screen and (max-width: $sm) {
            text-align: left;
        }
        p {
            font-size: 18px;
            line-height: 1.75;
            @media screen and (max-width: $lg) {
                font-size: 16px;
            }
            @media screen and (max-width: $md) {
                font-size: 14px;
            }
            @media screen and (max-width: $sm) {
                display: inline;
            }
        }
    }
    .btnBox {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 40px;
        @media screen and (max-width: $md) {
            
        }
        @media screen and (max-width: $sm) {
            margin-bottom: 25px;
            justify-content: space-between;
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0 20px;
            background-color: rgba(77, 77, 77, 0.45);
            border-radius: 5px;
            width: 419px;
            height: 220px;
            font-family: $font-secondary;
            font-weight: normal;
            &:last-child {
                @media screen and (max-width: $md) {
                    p {
                        width: 90%;
                        height: 65px;
                    }
                }
                @media screen and (max-width: $sm) {
                    p {
                        height: 50px;
                        padding: 5px 10px;
                    }
                }
            }
            @media screen and (max-width: $sm) {
                width: 90%;
                height: 158px;
                margin: 0 auto;
            }
            h3 {
                font-size: 38px;
                margin-bottom: 30px;
                @media screen and (max-width: $md) {
                    font-size: 32px;
                }
                @media screen and (max-width: $sm) {
                    font-size: 20px;
                    margin-bottom: 20px;
                }
            }
            p {
                text-align: center;
                min-width: 230px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                border: 1px solid #FFF;
                padding: 0 20px;
                &.sp {
                    display: none;
                }
                @media screen and (max-width: $md) {
                    font-size: 18px;
                }
                @media screen and (max-width: $sm) {
                    font-size: 12px;
                    padding: 0 15px;
                    min-width: 90%;
                    &.pc {
                        display: none;
                    }
                    &.sp {
                        display: block;
                    }
                }
            }
        }
    }
    .link {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 24px;
        font-weight: normal;
        font-family: $font-secondary;
        position: relative;
        @media screen and (max-width: $lg) {
            font-size: 20px;
        }
        @media screen and (max-width: $md) {
            font-size: 16px;
        }
        @media screen and (max-width: $sm) {
            justify-content: center;
            font-size: 18px;
        }
        svg {
            margin-left: 15px;
            width: 32px;
            height: 6px;
            position: relative;
            transition: $animate;
            right: 0;
            @media screen and (max-width: $lg) {
                width: 30px;
                height: 5px;
            }
            @media screen and (max-width: $sm) {
                margin-left: 10px;
                width: 26px;
                height: 4px;
            }
        }
        &:hover {
            svg {
                right: -5px;
            }
        }
    }
}

#conceptSection {
    padding: 120px 7.5% 150px;
    position: relative;
    @media screen and (max-width: $xl) {
        padding: 100px 5%;
    }
    @media screen and (max-width: $lg) {
        padding: 60px 3%;
    }
    @media screen and (max-width: $sm) {
        padding: 30px 10% 50px;
    }
    #concept {
        position: absolute;
        top: 0;
        margin-top: -90px;
        @media screen and (max-width: $sm) {
            margin-top: -60px;
        }
    }
    .rooftop {
        position: absolute;
        width: 83%;
        left: -17%;
    }
    .headingSection {
        font-family: $font-secondary;
        font-weight: normal;
        margin-bottom: 50px;
        position: relative;
        @media screen and (max-width: $sm) {
            text-align: center;
            margin-bottom: 15px;
        }
        p {
            font-size: 20px;
            color: #939393;
            @media screen and (max-width: $sm) {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
        h2 {
            font-size: 38px;
            letter-spacing: 5px;
            @media screen and (max-width: $sm) {
                font-size: 18px;
                -webkit-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                letter-spacing: 3px;
                position: relative;
                font-feature-settings: "palt";
                margin: auto;
                text-align: left;
                line-height: 2;
            }
            &.for-desktop {
                @media screen and (max-width: $sm) {
                    display: none;
                }
            }
            &.for-mobile {
                display: none;
                @media screen and (max-width: $sm) {
                    display: block;
                }
            }
        }
    }
    .content {
        display: flex;
        justify-content: flex-start;
        position: relative;
        @media screen and (max-width: $sm) {
            flex-direction: column;
        }
        .text {
            width: 51%;
            padding-left: 4%;
            padding-top: 25px;
            @media screen and (max-width: $xl) {
                padding-left: 0;
                padding-top: 0;
            }
            @media screen and (max-width: $sm) {
                width: 100%;
            }
            .title {
                font-size: 32px;
                font-family: $font-secondary;
                font-weight: normal;
                margin-bottom: 30px;
                @media screen and (max-width: $sm) {
                    font-size: 19px;
                    text-align: center;
                    margin-bottom: 15px;
                }
                h3 {
                    line-height: 1.75;
                }
            }
            .desc {
                @media screen and (max-width: $sm) {
                    margin-bottom: 30px;
                }
                p {
                    line-height: 2;
                    @media screen and (max-width: $lg) {
                        font-size: 15px;
                    }
                    @media screen and (max-width: $sm) {
                        font-size: 14px;
                        display: inline;
                    }
                }
            }
        }
        .images {
            flex: 1;
            ul {
                display: flex;
                justify-content: flex-start;
                @media screen and (max-width: $sm) {
                    justify-content: space-between;
                }
                li {
                    width: 44%;
                    margin-right: 3.21%;
                    background-color: #ADADAD;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    color: #FFF;
                    border-radius: 10px;
                    font-family: $font-secondary;
                    font-weight: normal;
                    transition: $animate;
                    @media screen and (max-width: $sm) {
                        margin-right: 0;
                        width: 48%;
                    }
                    &:hover {
                        background-color: $color-hover;
                    }
                    a {
                        padding: 19.7% 10px;
                        display: block;
                        width: 100%;
                        text-align: center;
                        @media screen and (max-width: $sm) {
                            padding: 20.5% 10px;
                        }
                    }
                    h3 {
                        font-size: 74px;
                        line-height: 1.3;
                        @media screen and (max-width: $lg) {
                            font-size: 65px;
                        }
                        @media screen and (max-width: $sm) {
                            font-size: 40px;
                        }
                    }
                    p {
                        font-size: 37px;
                        @media screen and (max-width: $lg) {
                            font-size: 28px;
                        }
                        @media screen and (max-width: $sm) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

#newHouse {
    position: relative;
    #new-house,
    #reform {
        position: absolute;
        top: 0;
        margin-top: -90px;
        @media screen and (max-width: $sm) {
            margin-top: -60px;
        }
    }
    .for-desktop {
        @media screen and (max-width: $sm) {
            display: none;
        }
    }
    .for-mobile {
        display: none;
        @media screen and (max-width: $sm) {
            text-align: center;
            display: block;
        }
    }
    .headingSection {
        position: absolute;
        width: 17.7%;
        height: 100%;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        font-family: $font-secondary;
        font-weight: normal;
        letter-spacing: 3px;
        @media screen and (max-width: $sm) {
            font-size: 14px;
            right: 5px;
            height: auto;
        }
        h2 {
            font-size: 40px;
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            @media screen and (max-width: $md) {
                font-size: 32px;
            }
            @media screen and (max-width: $sm) {
                font-size: 20px;
                line-height: 1.3;
            }
        }
        span {
            font-size: 22px;
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            color: #939393;
            @media screen and (max-width: $md) {
                font-size: 16px;
            }
            @media screen and (max-width: $sm) {
                font-size: 14px;
            }
        }
    }
    .inner {
        width: 82.3%;
        @media screen and (max-width: $sm) {
            width: 100%;
        }
        .top-img,
        .bottom-img {
            position: relative;
            @media screen and (max-width: $sm) {
                width: 77.4%;
            }
            .catchcopy {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                h2 {
                    font-family: $font-secondary;
                    font-size: 38px;
                    font-weight: normal;
                    letter-spacing: 10px;
                    @media screen and (max-width: $lg) {
                        font-size: 32px;
                    }
                    @media screen and (max-width: $md) {
                        font-size: 26px;
                    }
                    @media screen and (max-width: $sm) {
                        font-size: 4.8vw;
                        letter-spacing: 7px;
                    }
                }
            }
            img {
                width: 100%;
            }
            .PC {
                @media screen and (max-width: $sm) {
                    display: none;
                }
            }
            .SP {
                display: none;
                @media screen and (max-width: $sm) {
                    display: block;
                }
            }
        }
        .links {
            padding: 75px 0;
            @media screen and (max-width: $xl) {
                padding: 70px 0;
            }
            @media screen and (max-width: $lg) {
                padding: 60px 0;
            }
            @media screen and (max-width: $sm) {
                padding: 35px 0 10px;
            }
            ul {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
                @media screen and (max-width: 1300px) {
                    justify-content: space-between;
                    padding: 0 3%;
                }
                @media screen and (max-width: $md) {
                    justify-content: center;
                }
                @media screen and (max-width: $sm) {
                    padding: 0 10%;
                }
                li {
                    width: 442px;
                    margin-left: 123px;
                    margin-bottom: 30px;
                    @media screen and (max-width: 1300px) {
                        width: 48.5%;
                        margin-left: 0;
                    }
                    @media screen and (max-width: $md) {
                        width: 70%;
                    }
                    @media screen and (max-width: $sm) {
                        width: 100%;
                    }
                    &:first-child {
                        margin-left: 0;
                        margin-right: 29px;
                        @media screen and (max-width: 1300px) {
                            margin-right: 0;
                        }
                    }
                    &:nth-child(2) {
                        margin-right: 29px;
                        @media screen and (max-width: 1300px) {
                            margin-right: 0;
                        }
                    }
                    &:nth-child(3) {
                        margin-left: 0;
                    }
                    &:last-child {
                        width: 500px;
                        @media screen and (max-width: 1300px) {
                            width: 48.5%;
                        }
                        @media screen and (max-width: $md) {
                            width: 70%;
                        }
                        @media screen and (max-width: $sm) {
                            width: 100%;
                        }
                    }
                    a {
                        display: block;
                        .topLink {
                            position: relative;
                            padding: 50px 0 0;
                            font-family: $font-secondary;
                            font-weight: normal;
                            @media screen and (max-width: $lg) {
                                padding: 35px 0 0;
                            }
                            span {
                             position: absolute;
                             top: 0;
                             left: 50%;
                             transform: translate(-50%, 0);
                             font-size: 92px;
                             color: #E0E0E0;   
                             line-height: 1;
                             @media screen and (max-width: $lg) {
                                font-size: 75px;
                            }
                            @media screen and (max-width: $sm) {
                                font-size: 71px;
                            }
                            }
                            h3 {
                                font-size: 29px;
                                text-align: center;
                                position: relative;
                                @media screen and (max-width: $lg) {
                                    font-size: 22px;
                                }
                                @media screen and (max-width: $sm) {
                                    font-size: 20px;
                                }
                            }
                        }
                        .line {
                            margin: 25px 0;
                            width: 100%;
                            height: 1px;
                            background-color: #E0E0E0;
                            position: relative;
                            @media screen and (max-width: $lg) {
                                margin: 15px 0;
                            }
                            &:before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 33px;
                                height: 1px;
                                background-color: #333333;
                                z-index: 1;
                            }
                        }
                        .desc {
                            font-size: 15px;
                            color: #808080;
                            text-align: center;
                            letter-spacing: 0;
                            line-height: 1.75;
                            @media screen and (max-width: $lg) {
                                font-size: 14px;
                            }
                            @media screen and (max-width: $sm) {
                                p {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.reform {
        margin: 0 0 96px;
        @media screen and (max-width: $lg) {
            margin: 0 0 60px;
        }
        @media screen and (max-width: $sm) {
            margin: 0;
        }
        .inner {
            .top-img {
                .catchcopy {
                    @media screen and (max-width: $sm) {
                        flex-direction: column-reverse;
                    }
                    .desc {
                        font-size: 1.5vw;
                        line-height: 1.75;
                        margin-bottom: 30px;
                        @media screen and (max-width: $sm) {
                            margin: 7px 0 0;
                            font-size: 3.8vw;
                        }
                    }
                }
            }
            .bottom-img {
                @media screen and (max-width: $sm) {
                    width: 100%;
                }
                .catchcopy {
                    h2 {
                        margin-bottom: 45px;
                        @media screen and (max-width: $sm) {
                            margin-bottom: 10px;
                        }
                    }
                    .desc {
                        font-size: 1.2vw;
                        @media screen and (max-width: $sm) {
                            font-size: 3.8vw;
                        }
                    }
                }
            }
            .links {
                padding: 75px 0 85px;
                @media screen and (max-width: 1300px) {
                    padding: 70px 0;
                }
                @media screen and (max-width: $lg) {
                    padding: 60px 0;
                }
                @media screen and (max-width: $sm) {
                    padding: 60px 0 50px;
                }
                ul {
                    flex-wrap: nowrap;
                    @media screen and (max-width: $md) {
                        flex-wrap: wrap;
                    }
                    li {
                        margin: 0;
                        margin-left: 140px;
                        @media screen and (max-width: 1300px) {
                            margin-left: 0;
                        }
                        &:first-child {
                            margin: 0;
                            @media screen and (max-width: $md) {
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#portfolioSection {
    position: relative;
    padding-bottom: 70px;
    @media screen and (max-width: 1300px) {
        padding-bottom: 30px;
    }
    @media screen and (max-width: $sm) {
        padding-bottom: 0;
    }
    .headingSection {
        border-bottom: 1px solid #C6C6C6;
        padding-bottom: 15px;
        padding-left: 110px;
        display: inline-block;
        margin-bottom: 35px;
        @media screen and (max-width: $lg) {
            padding-left: 3%;
        }
        @media screen and (max-width: $sm) {
            padding-left: 25px;
            padding-bottom: 10px;
        }
        h2 {
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 26px;
            @media screen and (max-width: $lg) {
                font-size: 22px;
            }
            @media screen and (max-width: $sm) {
                font-size: 15px;
            }
        }
    }
    .portfolio-slider {
        padding-left: 110px;
        position: relative;
        @media screen and (max-width: 1300px) {
            padding-left: 3%;
        }
        @media screen and (max-width: $lg) {
            padding-left: 30px;
        }
        .btnMore {
            position: absolute;
            right: 53px;
            top: -42px;
            width: 127px;
            height: 22px;
            background-color: #666666;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-size: 13px;
            font-weight: normal;
            font-family: $font-secondary;
            transition: $animate;
            @media screen and (max-width: $lg) {
                right: 30px;
            }
            @media screen and (max-width: $sm) {
                position: relative;
                right: auto;
                top: auto;
                margin: 30px auto 0;
                width: 119px;
                height: 20px;
                letter-spacing: 0;
            }
            &:hover {
                background-color: $color-hover;
            }
        }
        .portfolio {
            overflow: hidden;
            .swiper-wrapper {
                .swiper-slide {
                    width: 340px !important;
                    position: relative;
                    @media screen and (max-width: $lg) {
                        width: 275px !important;
                    }
                    @media screen and (max-width: $sm) {
                        width: 204px !important;
                    }
                    .img {
                        width: 100%;
                        height: 260px;
                        overflow: hidden;
                        @media screen and (max-width: $lg) {
                            height: 210px;
                        }
                        @media screen and (max-width: $sm) {
                            height: 156px;
                        }
                        div {
                            width: 100%;
                            height: 100%;
                            transition: $animate;
                            transform: scale(1);
                        }
                    }
                    .text {
                        position: absolute;
                        background-color: #FFF;
                        bottom: 0;
                        left: 0;
                        width: 180px;
                        padding: 12px 15px;
                        font-family: $font-secondary;
                        font-weight: normal;
                        @media screen and (max-width: $lg) {
                            padding: 10px 15px;
                        }
                        @media screen and (max-width: $sm) {
                            padding: 10px;
                            width: 108px;
                        }
                        p {
                            font-size: 13px;
                            color: #808080;
                            @media screen and (max-width: $lg) {
                                font-size: 12px;
                            }
                            @media screen and (max-width: $sm) {
                                font-size: 8px;
                            }
                        }
                        h2 {
                            font-size: 16px;
                            @media screen and (max-width: $sm) {
                                font-size: 10px;
                            }
                        }
                    }
                    &:hover {
                        .img {
                            div {
                                transform: scale(1.05);
                            }
                        }
                    }
                }
            }
            .swiper-button-next,
            .swiper-button-prev {
                width: auto;
                height: auto;
                top: -10px;
                @media screen and (max-width: $sm) {
                    top: -5px;
                }
                &:after {
                    display: none;
                }
                img {
                    width: 30px;
                    @media screen and (max-width: $sm) {
                        width: 28px;
                    }
                }
            }
            .swiper-button-next {
                right: 203px;
                @media screen and (max-width: $sm) {
                    right: 55px;
                }
                &:after {
                    display: none;
                }
            }
            .swiper-button-prev {
                left: auto;
                right: 253px;
                transform: scaleX(-1);
                @media screen and (max-width: $sm) {
                    right: 93px;
                }
                &:after {
                    display: none;
                }
            }
        }
    }
}

#voiceSection {
    padding: 70px 0 100px;
    position: relative;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1300px) {
        padding: 30px 0 70px;
    }
    @media screen and (max-width: $md) {
        flex-direction: column;
    }
    @media screen and (max-width: $sm) {
        padding: 60px 0 50px;
    }
    #voice {
        position: absolute;
        top: 0;
        margin-top: -90px;
        @media screen and (max-width: $sm) {
            margin-top: -60px;
        }
    }
    .leftSection {
        width: 54%;
        @media screen and (max-width: $md) {
            width: 100%;
        }
        .pl {
            padding-left: 21%;
            @media screen and (max-width: $xl) {
                padding-left: 10%;
            }
            @media screen and (max-width: 1300px) {
                padding-left: 7.5%;
            }
            @media screen and (max-width: $lg) {
                padding-left: 5%;
            }
            @media screen and (max-width: $md) {
                padding: 30px;
            }
            @media screen and (max-width: $sm) {
                padding: 0 10%;
            }
        }
        .headingSection {
            margin-bottom: 30px;
            @media screen and (max-width: $sm) {
                margin-bottom: 10px;
            }
            span {
                display: block;
                color: #939393;
                font-size: 22px;
                font-weight: normal;
                font-family: $font-secondary;
                margin-bottom: 5px;
                @media screen and (max-width: $xl) {
                    font-size: 20px;
                }
                @media screen and (max-width: 1300px) {
                    font-size: 18px;
                }
                @media screen and (max-width: $lg) {
                    font-size: 16px;
                }
                @media screen and (max-width: $sm) {
                    font-size: 14px;
                }
            }
            h2 {
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 38px;
                letter-spacing: 10px;
                @media screen and (max-width: 1300px) {
                    font-size: 35px;
                }
                @media screen and (max-width: $lg) {
                    font-size: 30px;
                }
                @media screen and (max-width: $sm) {
                    font-size: 20px;
                    letter-spacing: 2px;
                }
            }
        }
        .desc {
            line-height: 2;
            margin-bottom: 40px;
            @media screen and (max-width: 1300px) {
                margin-bottom: 30px;
            }
            @media screen and (max-width: $lg) {
                font-size: 15px;
            }
            @media screen and (max-width: $sm) {
                font-size: 14px;
                line-height: 1.5;
                margin-bottom: 15px;
            }
        }
        .note {
            text-align: right;
            @media screen and (max-width: $md) {
                padding-right: 3%;
            }
            @media screen and (max-width: $sm) {
                display: none;
            }
        }
        .listQuestion {
            margin-bottom: 30px;
            @media screen and (max-width: $sm) {
                margin-bottom: 0;
            }
            .item {
                padding: 50px 0 40px 21%;
                border-bottom: 0.5px solid #000;
                @media screen and (max-width: $xl) {
                    padding: 50px 0 40px 10%;
                }
                @media screen and (max-width: 1300px) {
                    padding: 30px 0 20px 7.5%;
                }
                @media screen and (max-width: $md) {
                    padding: 20px 7.5%;
                }
                @media screen and (max-width: $sm) {
                    padding: 20px 15px;
                }
                .title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    line-height: 1;
                    font-family: $font-secondary;
                    font-weight: normal;
                    margin-bottom: 15px;
                    @media screen and (max-width: $md) {
                        justify-content: center;
                    }
                    @media screen and (max-width: $sm) {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 55px;
                        font-weight: bold;
                        color: #ADADAD;
                        margin-right: 15px;
                        @media screen and (max-width: 1300px) {
                            font-size: 45px;
                        }
                        @media screen and (max-width: $sm) {
                            font-size: 34px;
                            margin-right: 0;
                            margin-bottom: 10px;
                        }
                    }
                    h2 {
                        font-size: 26px;
                        letter-spacing: 5px;
                        position: relative;
                        top: -3px;
                        @media screen and (max-width: 1300px) {
                            font-size: 22px;
                        }
                        @media screen and (max-width: $sm) {
                            font-size: 18px;
                            top: auto;
                            letter-spacing: 2px;
                        }
                    }
                }
                .counterBox {
                    line-height: 1;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 75px;
                    @media screen and (max-width: 1300px) {
                        padding-left: 60px;
                    }
                    @media screen and (max-width: $md) {
                        padding-left: 0;
                        justify-content: center;
                    }
                    h2 {
                        font-size: 94px;
                        font-family: "Times New Roman", Times, serif;
                        margin-right: 20px;
                        @media screen and (max-width: 1300px) {
                            font-size: 75px;
                        }
                        @media screen and (max-width: $sm) {
                            font-size: 60px;
                            margin-right: 12px;
                        }
                        span {
                            font-size: 60px;
                            margin-left: -20px;
                            @media screen and (max-width: 1300px) {
                                font-size: 48px;
                            }
                            @media screen and (max-width: $sm) {
                                font-size: 37px;
                                margin-left: -15px;
                            }
                        }
                    }
                    .counter {
                        width: 250px;
                        position: relative;
                        top: 10px;
                        @media screen and (max-width: $sm) {
                            width: 157px;
                        }
                        img {
                            width: 100%;
                            position: relative;
                            z-index: 1;
                        }
                        .line {
                            position: absolute;
                            left: 5px;
                            bottom: 0;
                            width: 230px;
                            @media screen and (max-width: $sm) {
                                left: 3px;
                                width: 145px;
                            }
                            span {
                                display: block;
                                width: 100%;
                                height: 13px;
                                background-color: #ECECEC;
                                @media screen and (max-width: $sm) {
                                    height: 8px;
                                }
                                &.eighty {
                                    width: 80%;
                                }
                                &.seventy-two {
                                    width: 72%;
                                }
                                &.fifty-eight {
                                    width: 85%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .rightSection {
        width: 42.4%;
        padding: 133px 4% 105px 0;
        max-height: 1158px;
        overflow: hidden;
        @media screen and (max-width: $lg) {
            padding-top: 80px;
            max-height: 900px;
        }
        @media screen and (max-width: $md) {
            width: 100%;
            padding: 90px 0 0 30px;
            max-height: auto;
            overflow: unset;
            position: relative;
        }
        .btnBox {
            position: absolute;
            bottom: 70px;
            right: 4%;
            @media screen and (max-width: 1300px) {
                bottom: 30px;
            }
            @media screen and (max-width: $lg) {
                bottom: 70px;
            }
            @media screen and (max-width: $md) {
                position: relative;
                bottom: auto;
                right: auto;
                margin-top: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            a {
                width: 126px;
                height: 22px;
                font-size: 12px;
                background-color: #666666;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
            }
        }
        // &:before {
        //     content: "";
        //     position: absolute;
        //     right: 0;
        //     bottom: 110px;
        //     width: 42.4%;
        //     height: 105px;
        //     background-color: #FFF;
        //     z-index: 2;
        // }
        .notice-slider-sp {
            display: none;
            @media screen and (max-width: $md) {
                display: block;
            }
            .notice-sp {
                overflow: hidden;
                .swiper-wrapper {
                    .swiper-slide {
                        width: 294px !important;
                        position: relative;
                        border: 1px solid #333;
                        @media screen and (max-width: $md) {
                            width: 250px !important;
                        }
                        .img {
                            width: 100%;
                            height: 150px;
                            overflow: hidden;
                            div {
                                width: 100%;
                                height: 100%;
                                transition: $animate;
                                transform: scale(1);
                            }
                        }
                        .text {
                            position: relative;
                            background-color: #FFF;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            padding: 10px 12px;
                            span {
                                font-size: 13px;
                                color: #808080;
                                display: block;
                                margin-bottom: 5px;
                            }
                            h2 {
                                display: none;
                            }
                            p {
                                font-size: 14px;
                                overflow: hidden;
                                max-height: 84px;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 4;
                                -webkit-box-orient: vertical;
                            }
                        }
                        &:hover {
                            .img {
                                div {
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
                }
                .swiper-button-next,
                .swiper-button-prev {
                    width: auto;
                    height: auto;
                    top: 75px;
                    &:after {
                        display: none;
                    }
                    img {
                        width: 30px;
                        @media screen and (max-width: $sm) {
                            width: 28px;
                        }
                    }
                }
                .swiper-button-next {
                    right: 53px;
                    @media screen and (max-width: $sm) {
                        right: 55px;
                    }
                    &:after {
                        display: none;
                    }
                }
                .swiper-button-prev {
                    left: auto;
                    right: 103px;
                    transform: scaleX(-1);
                    @media screen and (max-width: $sm) {
                        right: 93px;
                    }
                    &:after {
                        display: none;
                    }
                }
            }
        }
        .notice {
            position: relative;
            @media screen and (max-width: $md) {
                display: none;
            }
            .swiper-wrapper {
                .swiper-slide {
                    width: 100%;
                    height: 18.8vw !important;
                    overflow: hidden;
                    border: 1px solid #333333;
                    display: flex;
                    margin-bottom: 40px;
                    .img {
                        width: 50%;
                        height: 100%;
                        div {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        width: 50%;
                        padding: 35px 15px 35px 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        @media screen and (max-width: $lg) {
                            padding: 15px;
                        }
                        span {
                            font-size: 14px;
                            color: #808080;
                            display: block;
                            margin-bottom: 10px;
                        }
                        h2 {
                            display: none;
                        }
                        p {
                            font-size: 14px;
                            overflow: hidden;
                            max-height: 105px;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            .swiper-button-next,
            .swiper-button-prev {
                width: 65px;
                height: 65px;
                @media screen and (max-width: $lg) {
                    width: 50px;
                    height: 50px;
                }
                &:after {
                    display: none;
                }
            }
            .swiper-button-prev {
                top: -1.3%;
                left: auto;
                right: 50%;
                transform: translate(50%, 0);
                @media screen and (max-width: $lg) {
                    top: -1%;
                }
            }
            .swiper-button-next {
                bottom: -1.3%;
                left: auto;
                right: 50%;
                transform: translate(50%, 0);
            }
        }
    }
}